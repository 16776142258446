import React from "react";

function ReviewsScreen() {
  return (
    <div>
      {/* Form or list for reviews */}
      <h2>Reviews</h2>
    </div>
  );
}

export default ReviewsScreen;
