export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_DETAILS_REQUEST= 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS ='PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'


export const LATEST_PRODUCT_LIST_REQUEST = 'LATEST_PRODUCT_LIST_REQUEST'
export const LATEST_PRODUCT_LIST_SUCCESS = 'LATEST_PRODUCT_LIST_SUCCESS'
export const LATEST_PRODUCT_LIST_FAIL = 'LATEST_PRODUCT_LIST_FAIL'


export const DEALS_PRODUCT_LIST_REQUEST = 'DEALS_PRODUCT_LIST_REQUEST'
export const DEALS_PRODUCT_LIST_SUCCESS = 'DEALS_PRODUCT_LIST_SUCCESS'
export const DEALS_PRODUCT_LIST_FAIL = 'DEALS_PRODUCT_LIST_FAIL'


export const MAYLIKE_PRODUCT_LIST_REQUEST = 'MAYLIKE_PRODUCT_LIST_REQUEST'
export const MAYLIKE_PRODUCT_LIST_SUCCESS = 'MAYLIKE_PRODUCT_LIST_SUCCESS'
export const MAYLIKE_PRODUCT_LIST_FAIL = 'MAYLIKE_PRODUCT_LIST_FAIL'

// export const RECOMM_PRODUCT_LIST_REQUEST = 'RECOMM_PRODUCT_LIST_REQUEST'
// export const RECOMM_PRODUCT_LIST_SUCCESS = 'RECOMM_PRODUCT_LIST_REQUEST'
// export const RECOMM_PRODUCT_LIST_FAIL = 'RECOMM_PRODUCT_LIST_FAIL'

export const FILTER_PRODUCTS_REQUEST = 'FILTER_PRODUCTS_REQUEST';
export const FILTER_PRODUCTS_SUCCESS = 'FILTER_PRODUCTS_SUCCESS';
export const FILTER_PRODUCTS_FAIL = 'FILTER_PRODUCTS_FAIL';
